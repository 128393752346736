import React from "react"
import { AnswerButtons } from "./"

export default function QuestionItem({
  answers,
  id,
  onAnswerClick,
  selected,
  text,
}) {
  return (
    <div className="question-item">
      <p>{text}</p>
      <AnswerButtons
        answers={answers}
        onClick={a => onAnswerClick(id, a)}
        selected={selected}
      />
    </div>
  )
}

QuestionItem.defaultProps = {
  answers: [],
  id: null,
  selected: null,
  text: "",
}
