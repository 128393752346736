import React, { useState } from "react"
import * as Yup from "yup"
import BasicForm from "../../basicForm"

export default function QuestionForm({
  firstName,
  lastName,
  email,
  phone,
  onSubmit,
}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "last_name",
      label: "Last Name",
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "email",
      label: "Email Address",
      initialValue: email,
      required: true,
      schema: () =>
        Yup.string()
          .email("Not a valid email")
          .required("Required"),
    },
    {
      name: "phone",
      label: "Direct Phone Line",
      initialValue: phone,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
  ]

  return (
    <BasicForm
      loading={loading}
      error={error}
      onSubmit={onSubmit}
      fields={fields}
      buttonText="Submit"
    />
  )
}

QuestionForm.defaultProps = {
  id: null,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  onSuccess: () => {},
}
