import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"
import { IndividualSurvey } from "../components/survey"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const CrackleGlass = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout heroTitle="CRACKLE GLASS" heroBackgroundImage="/images/crackle-glass/header-crackle-glass.jpg">
      <SEO
        title="Crackle Glass | ArtVue Glass"
        ogDescription="Find decorative glass that is unique and one of a kind. Creates unique patterns as light refracts in thousands of directions while being completely safe – no sharp edges! ArtVue Glass has high quality crackle glass for your home or business."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>CRACKLE GLASS</h1>
            <h2>GLASS THAT SPARKLES</h2>
            <p>
              Are you looking for one-of-a-kind decorative glass? Then consider crackle glass. Because of the many small pieces, light refracts in thousands of directions while being completely safe – no sharp edges. The finished crackle glass piece is perfectly safe, strong, and durable.
            </p>
            <p>
              ArtVue Glass completes all crackled glass in-house and is made to order. With the multitude of shapes, sizes, and color options available, a custom crackled piece of glass will provide a conversation centerpiece for any interior space.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/crackle-glass/crackle-glass-tabletop.jpg" alt="Crackle Glass Tabletop" />
          </div>
        }
      />
      <div className="section textured">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Features and Benefits of Crackle Glass</h2>
            <p>
              <strong>Custom:</strong> Size and thickness based on your requirements.  Utilize low iron glass for an ultra-clear look, combine with back painting or a mirror backing too.
            </p>
            <p>
              <strong>Safe:</strong> Since crackle glass is a laminated, sandwiched composition, no sharp edges are present and the small, shattered pieces inside remain intact.
            </p>
            <p>
              <strong>Durable:</strong> Due to its construction, crackle glass is more robust than regular panes of glass and less likely to break or crack.
            </p>
            <p>
              <strong>Versatile:</strong> Use it almost anywhere in a home or commercial setting.
            </p>
            <p>
              <strong>Stylish:</strong> Thanks to its unique appearance that combines both contemporary features and a modern look, it's hard to find something quite as eye-catching yet functional.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/crackle-glass/custom-cracked-glass-samples.jpg" alt="Custom Crackle Glass Samples" />
          </div>
        }
      />
      </div>
      <PageWrapper color="grey">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/crackle-glass/crackle-glass-before-and-after.png" alt="Crackle Glass Before and After" />
                      <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <h2>How is Crackled Glass Made?</h2>
            <p>
              First, three pieces of glass are sandwiched together. The middle piece of tempered glass is then purposefully shattered in a controlled break without affecting the remaining two outer parts. The result is a random yet consistent shatter pattern with many different edges to reflect light.            </p>
            <h2>Applications of Crackle Glass</h2>
            <p>
              The number of different applications of crackled glass is unlimited. Many customers request crackled glass for tabletops, dining tables, and coffee tables in a home and office environment. The applications do not stop there. Glass partitions, countertops, trendy bars, hotel lobbies, relaxing lounges, and casinos are also possibilities.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>What is Crackle Glass?</h2>
            <p>
              Uneven surface, icy look, and smooth texture; crackle glass is not just any shattered glass. Its unique and exclusive design makes any piece of décor, such as tabletops and kitchen backsplashes, an extraordinary addition to your home.
            </p>
            <p>
              Unsurprisingly, crackle glass is popular all over the world, referred to with various names, including ice glass, craquelle, and overshot glass.
            </p>
            <p>
              At ArtVue Glass, we manufacture crackled glass of the highest quality, design, and durability to offer your home an innovative, trendy, and reliable interior option.
            </p>
            <h2>History Of Crackle Glass</h2>
            <p>
              Laminating multiple layers of glass together and shattering the middle layer is somewhat of a modern design concept with its roots in European artisan glass makers.
            </p>
            <p>
              As early as the 16th century, glass artists in Venice came up with a unique method to develop crackled glass. First, they would blow molten glass into a small bulb, then submerging molten hot glass in cold water, with the sudden shift from extreme heat to extreme cold, shattering the glass while still staying intact. 
            </p>
            <p>
              Afterward, it was reheated, shaped, and sealed to preserve its new look. Sometimes another layer of glass is added, and the crackle effect is totally enclosed. The technique requires skill and practice to void completely shattering the glass, and to avoid melting the cracks on reheating.
            </p>
            <p>
              Overshot glass is different, although it is often confused with crackle glass. With overshot glass the molten glass item is rolled over tiny splinters of glass on a heated marver (metal plate or bench-top) and then reheated to soften the very sharp edges on the splinters.
            </p>
            <p>
              Ever since crackle glass has become well-known around Europe and America, but techniques and tools have upgraded. At ArtVue Glass, we use modern, state-of-the-arts tools to achieve the crackled look safely and efficiently for flat glass applications.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Five Trendy Ways to Use Crackled Glass</h2>
            <p>
              There are numerous ways you can use and display cracked glass in your interior setting. At ArtVue Glass, we help you create the perfect look for your home or office with:
            </p>
            <h3>1. Crackled Glass Tabletop</h3>
            <p>
              Crackled glass tabletops breathe life and add elegance and class to open areas and living rooms.
            </p>
            <p>
              They’re incredibly easy to maintain and clean, and you don’t have to worry about them getting damaged that easily.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/crackle-glass/crackle-glass-backsplash.jpg" alt="Crackle Glass Backsplash" />
          </div>
        }
        textRight={
          <div>
            <h3>2. Crackled Glass Kitchen Backsplash & Worktops</h3>
            <p>
              Kitchens are constantly warm and humid. That’s why you not only need a trendy backsplash and worktop but also one is able to resist heat and maintain its true form.
            </p>
            <p>
              Crackled glass offers the best of both style and functionality. Its triple-layered structure protects it from the heat of burning hot pans and dishes, while its catchy design maintains the chic look of your kitchen.
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>3. Cracked Glass Coffee Table</h3>
            <p>
              Coffee tables are one of those small but impactful décor pieces that can make all the difference in your home’s interior. Crackled glass allows your coffee tables to provide a feeling of luxury to your daily life.
            </p>
            <h3>4. Cracked Glass Table</h3>
            <p>
              Normal glass or wooden tables simply don’t uplift a living space. They’re a dull and outdated presence nowadays.  
            </p>
            <p>
              If you want to emanate energy in the room, choose cracked glass tables and take your home’s visual appeal to the next level.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/crackle-glass/cracked-decorative-glass-coffee-table.jpg" alt="Cracked Glass Coffee Table" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/crackle-glass/cracked-decorative-glass-dining-table.jpg" alt="Cracked Glass Dining Table" />
          </div>
        }
        textRight={
          <div>
            <h3>5. Cracked Glass Dining Table</h3>
            <p>
              Home, food, family time – the dining table is a place for fun and forming memories. Crackled glass makes it all the more special by providing a classy and safe layering over your dining table.
            </p>
            <p>
              Give your home or office a contemporary makeover. Surf through ArtVue Glass’ customizable and exclusive cracked glass options for residential and commercial spaces. 
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Benefits of Crackled Glass</h2>
            <p>
              Crackled glass offers an array of benefits, including:
            </p>
            <h3>Uplifting Interior Aesthetics</h3>
            <p>
              Do you want to transform your home or office’s interior, enhance your living, and impress anyone that visits you?
            </p>
            <p>
              Then, embrace Crackle glass. It creates a distinctive vibe and immediately grabs attention. Plus, with countless designs, multiple thicknesses, and several color choices, you can blend crackled glass with your overall interior or play around with a bit of contrast. 
            </p>
            <p>
              Imagine your dream setting. Add cracked glass to it. Instantly, you can picture a captivating interior that creates an unforgettable impression and brightens up the room. 
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Reliable, Long Term Option</h3>
            <p>
              If you have children running around the house or a heavy footfall at the office, you can’t rely on normal glass panes to last long.
            </p>
            <p>
              Crackled glass is 6 times the safer choice! Use and decorate without the fear of breaking – it’s already achieved that shattering look!
            </p>
            <p>
              Invest in glass that saves costs in the long run and helps you lead a stress-free life.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/crackle-glass/crackled-glass-decorative-worktop.jpg" alt="Crackled Glass Decorative Worktop" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Stays Cool, <i>Literally</i></h3>
            <p>
              Heat can damage many appliances, tabletops, and décor. But crackled glass stays cool and firm in hot and humid places, freeing you from the headache of choosing any other glass for your kitchen.
            </p>
            <p>
              Now, you can keep your cooking area as elegant as the rest of your home!
            </p>
            <h3>Unique & Stylish Décor</h3>
            <p>
              You can opt for clear crackled glass or frosted – the choice is totally yours.
            </p>
            <p>
              Whether you want modern transparent workspaces or you prefer your own privacy, you can have the best of both the worlds. 
            </p>
            <p>
              At the same time, there are so many ways you can boost functionality and visual appeal with crackled glass décor by customizing however you want. 
            </p>
              
            <p>
              At ArtVue Glass, we help you achieve any interior you want to enhance your convenience and quality of living. Isn’t that just what you want?
            </p>
            <p>
              Redefine and breathe life into the places that matter to you with cracked glass!
            </p>
            <h4>Contact Us Today for A Free Consultation!</h4>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
      />
      </PageWrapper>
    </PageLayout>
  )
}

export default CrackleGlass
