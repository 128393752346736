import React from "react"
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa"

export default function Nav({ total, index, onClick }) {
  return (
    <div className="survey-nav">
      {index !== 0 && (
        <button onClick={() => onClick("back")} title="Previous Question">
          <FaArrowAltCircleLeft />
        </button>
      )}
      {index + 1 < total && (
        <button onClick={() => onClick("forward")} title="Next Question">
          <FaArrowAltCircleRight />
        </button>
      )}
    </div>
  )
}

Nav.defaultProps = {
  index: 0,
  total: 0,
}
