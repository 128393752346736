import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import questionData from "./questions.json"
import { Nav, QuestionItem } from "../components"
import EmbedForm from "./EmbedForm"
import QuestionForm from "./QuestionForm"

import "./index.scss"

const SURVEY_VIEW = "SURVEY"
const FORM_VIEW = "FORM"

const formattedQuestions = questionData.questions.map(q => ({
  ...q,
  selected: null,
  id: uuidv4(),
}))

export default function IndividualSurvey() {
  const [questions, setQuestions] = useState(formattedQuestions)
  const [activeQuestion, setActiveQuestion] = useState(formattedQuestions[0])
  const [activeView, setActiveView] = useState(SURVEY_VIEW)

  function onAnswerClick(questionId, answer) {
    const newQuestions = [...questions]
    const question = newQuestions.find(q => q.id === questionId)
    if (!question) return
    const idx = getActiveIdx()
    question.selected = answer

    setQuestions(newQuestions)

    const newActiveQuestion = newQuestions[idx + 1]

    if (newActiveQuestion) {
      setActiveQuestion(newActiveQuestion)
    }

    if (!newQuestions.find(q => !q.selected)) {
      setActiveView(FORM_VIEW)
    }
  }

  function formatSurveyValues() {
    let text = ""
    const qs = questions.map(q => ({ text: q.text, selected: q.selected }))
    qs.forEach(q => (text += `${q.text}\n${q.selected}\n\n`))
    return text
  }

  function onFormSubmit(formData) {
    const contactTypeField = document.getElementById("website_contact_type")
    const emailField = document.getElementById("email")
    const fNameField = document.getElementById("first_name")
    const lNameField = document.getElementById("last_name")
    const phoneField = document.getElementById("phone")
    const priorityField = document.getElementById("website_priority")
    const surveyField = document.getElementById("00N0y000006yxHH") // ???
    const formSubmit = document.getElementById("formSubmit")

    if (emailField) {
      emailField.value = formData.email
    }

    if (fNameField) {
      fNameField.value = formData.first_name
    }

    if (lNameField) {
      lNameField.value = formData.last_name
    }

    if (phoneField) {
      phoneField.value = formData.phone
    }

    if (surveyField) {
      surveyField.value = formatSurveyValues()
    }

    if (contactTypeField) {
      contactTypeField.value = "individual"
    }

    if (priorityField) {
      priorityField.value = "hot"
    }

    formSubmit.click()
  }

  function onNavClick(direction) {
    if (direction === "back") {
      setActiveQuestion(questions[getActiveIdx() - 1])
    } else {
      setActiveQuestion(questions[getActiveIdx() + 1])
    }
  }

  function getActiveIdx() {
    return questions.map(q => q.id).findIndex(q => q === activeQuestion.id)
  }

  return (
    <div className="survey">
      {activeView === SURVEY_VIEW && (
        <div>
          <p>
            For most people, building wealth means being able to enjoy your retirement and perhaps passing some of it along to your family. What would be the impact of a prolonged period of health-related struggles on your family, income, personal assets and investments?
          </p>
          <p>
            An unfunded or underfunded long term illness or disability can do irreparable harm to your family’s financial plans, investments as well as limiting your current and future income. There is also the emotional, mental and physical stress that long term care can put on family relationships. Without a plan, long term care is almost always provided by a person’s family.
          </p>
          <p>
            Long term care planning protects your retirement savings and investment so they can be used as you intended in the event you need long term care assistance. And it preserves your relationships with loved ones.
          </p>
          <p>
To get started, please answer these questions and we'll be in touch to take the next step with you.
          </p>
          <div>
            <QuestionItem
              key={activeQuestion.id}
              id={activeQuestion.id}
              text={activeQuestion.text}
              answers={activeQuestion.answers}
              extraText={activeQuestion.extra_text}
              selected={activeQuestion.selected}
              onAnswerClick={onAnswerClick}
            />
          </div>
          <Nav
            onClick={onNavClick}
            total={questions.length}
            index={getActiveIdx()}
          />
        </div>
      )}
      {activeView === FORM_VIEW && (
        <div className="survey-form">
          <QuestionForm onSubmit={onFormSubmit} />
        </div>
      )}
      <div className="view-nav">
        {activeView === FORM_VIEW && (
          <button onClick={() => setActiveView(SURVEY_VIEW)}>
            Back to questions
          </button>
        )}
        {!questions.find(q => !q.selected) && activeView === SURVEY_VIEW && (
          <div>
            <button onClick={() => setActiveView(FORM_VIEW)}>Finish</button>
          </div>
        )}
      </div>
      <div className="embed-form">
        <EmbedForm />
      </div>
    </div>
  )
}
