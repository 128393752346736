import React from "react"
import { v4 as uuidv4 } from "uuid"

export default function AnswerButtons({ answers, selected, onClick }) {
  return (
    <div className="answer-buttons">
      {answers.map(a => (
        <button
          className={selected === a ? "active" : ""}
          key={uuidv4()}
          onClick={() => onClick(a)}
        >
          {a}
        </button>
      ))}
    </div>
  )
}

AnswerButtons.defaultProps = {
  answers: [],
  selected: null,
}
